import React from 'react';
import {formatDate, getArticleLinks} from "../utils/articleUtil";
import cx from "classnames";

const ArticlePreview = props => {
    const className = cx(
        "",
        props.className,
    );
    const headingClass = cx(
        props.headingClass?.includes("text-") ? "" : "text-2xl",
        props.headingClass?.includes("mb-") ? "" : "mb-2",
        "block hover:underline",
        props.headingClass
    );
    const imageClass = cx(
        props.imageClass?.includes("mb-") ? "" : "mb-4",
        props.imageClass
    );
    const textClass = cx(
        "mb-0",
        props.textClass
    );

    const articleLinks = getArticleLinks(props.data);
    const category = articleLinks.category;
    const categoryPath = articleLinks.categoryPath;
    const getReadMore = articleLinks.getReadMore;

    const imageUrl = () => {
        let url = `${props.data.cover.url}?`;
        let qualityGiven = false;
        if (props.imageMeta) {
            const keys = Object.keys(props.imageMeta);
            keys.forEach((key) => {
                url += `${key}=${props.imageMeta[key]}&`;
                !qualityGiven && (qualityGiven = key === 'q')
            });
        }
        if (!qualityGiven) {
            url += 'q=50';
        }
        return url;
    }

    const getDynamicImage = (cover) => {
        if (cover.responsiveImage) {
            cover.responsiveImage.alt = cover.responsiveImage.alt || props.data.heading;
            return <amp-img src={cover.responsiveImage.src} width={cover.responsiveImage.width}
                            height={cover.responsiveImage.height} layout="responsive"/>;
        } else {
            return <amp-img
                src={cover.gatsbyImageData.images.fallback ? cover.gatsbyImageData.images.fallback.src : cover.url}
                width={cover.width} height={cover.height} layout="responsive" alt={props.data.heading}/>;
        }
    }

    const imageComponent = () => {
        return <figure className={imageClass}>
            <a href={getReadMore()}>
                {
                    props.imageFromUrl
                        ?
                        <img className="teaser-card__image" src={imageUrl()}/>
                        :
                        getDynamicImage(props.data.cover)
                }
            </a>
        </figure>
    }

    return (
        <div className={className} key={props.data.slug}
             style={props.style} data-datocms-noindex>
            {!props.noImage && props.data.cover?.url && imageComponent()}
            {!props.noFlag && <a className="block font-bold text-xs tracking-wider mb-2 text-denim-50 uppercase" href={categoryPath()}>{category.title}</a>}
            {props.dateAsFlag && <a
              className={"text-xs tracking-wider mb-2 text-denim-50"}
              href={`/authors/${props.data.author.slug}`}>{props.data.author.name}</a>}
            <a className={headingClass} href={getReadMore()}>{props.data.heading}</a>
            {!props.noBody && <>
                <p className={textClass}>{props.data.excerpt}</p>
                {!props.noReadMore && <a className="text-sm text-blue-600 mr-2" href={getReadMore()}>Read More</a>}
            </>}
            {!props.noAuthor && <>
                    <span className="text-13 block pt-1 text-black text-opacity-50">By <a className={'text-blue-600'}
                        href={`/authors/${props.data.author.slug}`}>{props.data.author.name}</a>
                        {/*{!props.noPublishedDate &&
                        <time className={'ml-2'}>{formatDate(props.data.publishedAt, 'mmmm dd, yyyy')}</time>}*/}
                    </span>
            </>}
        </div>
    )
}
export default ArticlePreview;
